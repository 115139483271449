/* Positional scss, padding margin etc. */
@for $i from 0 through 6 {
  $hi: ($i - 0.5);

  // Padding
  .p-a-#{$i} {
    padding: 1em * $i;
  }
  .p-a-h#{$i} {
    padding: 1em * $hi;
  }
  .p-b-#{$i} {
    padding-bottom: 1em * $i;
  }
  .p-b-h#{$i} {
    padding-bottom: 1em * $hi;
  }
  .p-t-#{$i} {
    padding-top: 1em * $i;
  }
  .p-t-h#{$i} {
    padding-top: 1em * $hi;
  }
  .p-r-#{$i} {
    padding-right: 1em * $i;
  }
  .p-r-h#{$i} {
    padding-right: 1em * $hi;
  }
  .p-l-#{$i} {
    padding-left: 1em * $i;
  }
  .p-l-h#{$i} {
    padding-left: 1em * $hi;
  }

  // Margin
  .m-a-#{$i} {
    margin: 1em * $i;
  }
  .m-a-h#{$i} {
    margin: 1em * $hi;
  }
  .m-b-#{$i} {
    margin-bottom: 1em * $i;
  }
  .m-b-h#{$i} {
    margin-bottom: 1em * $hi;
  }
  .m-t-#{$i} {
    margin-top: 1em * $i;
  }
  .m-t-h#{$i} {
    margin-top: 1em * $hi;
  }
  .m-r-#{$i} {
    margin-right: 1em * $i;
  }
  .m-r-h#{$i} {
    margin-right: 1em * $hi;
  }
  .m-l-#{$i} {
    margin-left: 1em * $i;
  }
  .m-l-h#{$i} {
    margin-left: 1em * $hi;
  }
}
