@import "../../styles/colors";

.checkmarkContainer {
  height: 1.5rem;
  width: 1.5rem;
  font-size: 22px;
  cursor: pointer;
  user-select: none;
}

/* Create a custom checkbox */
.checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: rgba($scout_grey, 0.5);
}

.checkboxMark {
  position: absolute;
  left: 10px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* Hide the browser's default checkbox */
.checkmarkContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* On mouse-over, add a grey background color */
.checkmarkContainer:hover input ~ .checkbox {
  background-color: rgba($scout_white, 0.8);
}

/* When the checkbox is checked, add a blue background */
.checkmarkContainer input:checked ~ .checkbox {
  background-color: $scout_blue;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox:after {
  content: "";
  position: absolute;
  display: none;
  left: 10px;
}

/* Show the checkmark when checked */
.checkmarkContainer input:checked ~ .checkmark:after {
  display: block;
}
