@import "../../styles/colors";

button:global(.btn):global(.btn-primary).choiceChipDeselected:hover:not(:global(.disabled)),
button:global(.btn):global(.btn-primary).choiceChipSelected {
  color: $scout_white;
  background-color: $scout_blue;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border: none;
}

button:global(.btn):global(.btn-primary).choiceChipDeselected {
  color: $scout_black;
  background-color: $scout_blue_washed;
  border: none;
}
