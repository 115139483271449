@import "../../components/card-container/card-container.module.scss";

.idBadge {
  color: $scout_blue;
}

.inviteBadge {
  color: $scout_grey;
}

.actionButton {
  padding: 0 !important;
  margin-left: 0 !important;
}
