@import "../../styles/colors";

.selectGroupDropdown {
  display: flex;
}
.selectGroupDropdown > button {
  width: 100%;
  margin: 0;
  background-color: $scout_darkgrey;
}
.selectGroupDropdownMenu {
  width: 100%;
}

.selectGroupDropdownToggleText {
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0.25rem;
  max-width: 80%;
  vertical-align: top;
}

.selectGroupDropdownItem {
  overflow: hidden;
  text-overflow: ellipsis;
}
