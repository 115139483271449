@import "../../styles/colors";

.session-card {
  &__thumbnail {
    background-color: $scout_black;
    height: 16rem;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    &__default-cursor {
      cursor: default;
    }

    img {
      height: 16rem;
      width: 100%;
      object-fit: cover;
    }

    &__overlay {
      position: absolute;
      width: 100%;
      height: 16rem;
      display: flex;

      &__no-video-span {
        color: $scout_white;
        font-weight: bold;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &__play-span {
        color: rgba(0, 0, 0, 0.3);

        &__icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          --fa-primary-color: white;
          --fa-secondary-color: black;
          --fa-secondary-opacity: 0.3;
        }
      }

      &__length {
        position: absolute;
        bottom: 4rem;
        padding: 0.2rem;
        background-color: black;
        margin: 0.3rem;
        border-radius: 3px;
        color: $scout_white;
        font-size: 12px;
        display: flex;
      }

      &__live {
        align-self: flex-end;
        background-color: $scout_red;
        z-index: 2; // render on top of the footer overlay in order to keep the bright red color
        width: 25px;
        height: 25px;
        // The '19px' values have been found by inspecting the elements in browser. The real fix should be to
        // include this as an element of the session card footer instead of the overlay.
        margin-bottom: 19px;
        margin-left: 19px;
        border-radius: 100%;
      }

      &__status {
        background-color: $scout_black;
        align-self: flex-start !important;
      }
    }

    &__spinner {
      position: absolute;
      width: 300px;
      height: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
