@import "../../styles/colors";

.container {
  position: relative;
  width: 8rem;
  height: 8rem;
  background-color: $scout_grey;
  border: 1px solid darkgrey;

  &__selected {
    outline: 2px solid $scout_blue;
  }
}

.thumbnail {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition-duration: 0.1s;

  &__inSelection {
    padding: 10px;
    transition-duration: 0.1s;
    background-clip: content-box, padding-box;
    box-shadow: inset 0 0 0 10px rgba($scout_blue, 0.2);
  }
}

.thumbnail:hover {
  cursor: pointer;
}

.videoLinkButton {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  width: 2.2rem;
  height: 2.2rem;
  border: none;
  background: rgba(0, 0, 0, 0.4);
  color: white;
}

.flag {
  position: absolute;
  top: 0.2rem;
  right: 2.4rem;
  width: 2.2rem;
  height: 2.2rem;
  border: none;
  background: rgba(0, 0, 0, 0.4);
  color: white;
}

.flag:hover {
  background: rgba(0, 0, 0, 0.6);
  color: white;
}

.videoLinkButton:hover {
  background: rgba(0, 0, 0, 0.6);
  color: white;
}

.label {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  font-size: 0.8rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkmark {
  position: absolute;
  top: 0.4rem;
  left: 0.4rem;
}
