@import "../../styles/colors";
@import "../../styles/mixins";

.newAsset {
  color: $scout_darkgrey;
  font-weight: 100;
  font-size: small;
}

.title {
  font-size: 1rem;
  color: $scout_dark_black;
}
