.modal_container {
  max-width: none !important;
  margin: 0;
}

.modal_content {
  margin: 0 50px 0 50px !important;
  height: calc(100vh - 100px) !important;
}

.modal_backdrop {
  opacity: 0.9 !important;
}
