@import "../../styles/colors";
@import "../../styles/mixins";
@import "../card-container/card-container.module.scss";

.contentItem {
  margin: 2rem;

  .videoTitle {
    padding: 0.2rem 1rem 1rem;
    font-weight: 500;
  }

  .video {
    height: 360px;
    max-width: 640px;
  }
}

.noReportsToShowMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
