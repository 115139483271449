@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/css?family=Titillium+Web");
@import "styles/colors";
@import "./styles/colors";
@import "./styles/mixins";
@import "./styles/breakpoints";

//SCROLLBAR
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $scout_red;
}

//BUTTONS
.scoutTemplate button {
  border-radius: 0.1rem;
}

//DROPDOWN
.scoutTemplate .dropdown > button {
  border-radius: 0.1rem;
}

//MODAL
.scoutTemplate .modal-content {
  border-radius: 0.1rem;
  border: none;
}
//MODAL-HEADER
.scoutTemplate .modal-header {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  background-color: $scout_dark_black;
  border-radius: 0.1rem;
  color: $scout_white;
}
.scoutTemplate .modal-header button.close {
  color: $scout_white;
  text-shadow: 0 0 black;
  opacity: 1;
  font-weight: 400;
}
//MODAL-BODY
.scoutTemplate .modal-body {
  padding-top: 0;
  padding-bottom: 0;
}
//MODAL-FOOTER
.scoutTemplate .modal-footer {
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  background-color: $scout_dark_black;
  border-radius: 0 0 0.1rem 0.1rem;
  color: $scout_white;
  min-height: 3.6rem;
  border-top: none;
}

.scoutTemplate.modal-dialog {
  max-height: calc(100% - 1rem);
  height: 100%;
  margin: 0.5rem auto;
}

// X-SMALL Screen (Phone)
@media screen and (min-width: 0px) and (max-width: $screen-md-min) {
  .scoutTemplate.modal-dialog {
    max-width: calc(100% - 1rem);
  }
}
// SMALL Screen (Tablet)
@media screen and (min-width: $screen-md-max) and (max-width: $screen-lg-min) {
  .scoutTemplate.modal-dialog {
    max-width: 80%;
  }
}
// Medium Screen (Laptop)
@media screen and (min-width: $screen-lg-max) and (max-width: $screen-xl-min) {
  .scoutTemplate.modal-dialog {
    max-width: 65%;
  }
}
// Large Screen (Desktop)
@media screen and (min-width: $screen-xl-min) {
  .scoutTemplate.modal-dialog {
    max-width: 50%;
    max-height: calc(100% - 1rem);
    height: 100%;
  }
}

//PAGINATION
.scoutTemplate .pagination li.page-item > button.page-link {
  &[aria-label="Last"] {
    border-radius: 0.1rem;
  }
  &[aria-label="First"] {
    border-radius: 0.1rem;
  }
}
.scoutTemplate .pagination li.page-item.active > button.page-link {
  background-color: $scout_red;
  border-color: $scout_red;
  z-index: 0;
}
.scoutTemplate .pagination li.page-item > button.page-link:focus {
  box-shadow: none;
}
.scoutTemplate ul.pagination {
  margin: 0.3rem;
}
