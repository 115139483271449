@import "../../styles/colors";
@import "../../styles/mixins";

.inlineCheckbox {
  margin-left: 0.5rem;
  width: 1.2rem;
  height: 1.2rem;
}

.generalized-form {
  margin-top: 1rem;
  // padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;

  &__form {
    width: 100%;
    width: 100%;
    @include for-tablet-landscape-up {
      width: 70%;
      align-items: center;
    }

    @include for-desktop-up {
      width: 60%;
      align-items: center;
    }

    @include for-big-desktop-up {
      width: 50%;
      align-items: center;
    }

    button {
      margin-right: 1rem;
    }

    &__body {
      display: flex;
      flex-flow: row;

      &__fields {
        flex: 3;

        &__image_input {
          padding-bottom: 1rem;
        }

        img {
          max-height: 160px;
          max-width: 320px;
          margin-bottom: 1rem;
          object-fit: cover;
        }
      }
    }
    &__button-group {
      display: flex;
      flex-flow: row;
      justify-content: center;
    }
  }
}
