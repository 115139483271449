@import "../../../../styles/colors";

.container {
  text-align: center;
}

.container h3 {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.streamAppLink {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: inline-block;
}
.storeLinks {
  margin: 2rem 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.storeLink {
  height: 4rem;
}
.storeLink img {
  height: 100%;
}
.appStoreLink img {
  padding: 5.5%;
}
