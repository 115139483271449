@import "../../styles/colors";
@import "../../styles/mixins";

.streamer-container {
  width: 100% !important;
  background-color: $scout_black;
  height: 100vh;
}

.Resizer {
  background-color: #4d4d4d;
  padding: 0.5rem;
  cursor: ew-resize;
  transition: background-color 200ms;
  z-index: 1;
}

.Resizer-closed {
  background-color: #4d4d4d;
  pointer-events: none;
  padding: 1px;
  z-index: 1;
}

.Resizer:hover {
  background-color: $scout_red;
}

.videoButton {
  position: absolute;
  right: 3%;
  top: 100%;
  color: #e6e6e6;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.loading-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}
.slider {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 100%;
  background: $scout_grey;
  outline: none;
}
/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: $scout_darkgrey;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: $scout_darkgrey;
  cursor: pointer;
}

.videoplayer-alone-container {
  display: flex;
  height: 100%;
  position: absolute;
  overflow: hidden;
  user-select: text;
  flex-direction: row;
  left: 0;
  right: 0;

  @include lt-md {
    height: 90%;
  }
}

.fa-long-arrow-alt-left {
  margin-right: 0.3rem;
}

.paneContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.loading-data {
  position: absolute;
  display: flex;
  align-items: center;
  top: 20px;
  right: 20px;
  font-weight: bold;
  color: $scout_white;
}

.loading-data-spinner {
  margin-right: 10px;
}
