@import "../../styles/colors";
@import "../../styles/breakpoints";

.findings-gallery {
  &__single-add-button-container {
    display: flex;
    flex-flow: row wrap;
  }

  &__modal-header {
    background-color: $scout_grey;
    border-bottom: none !important;
  }
}

@media (min-width: $screen-xl-min) {
  .modal-xl-larger.modal-xl {
    max-width: 80%;
  }
}
