@import "../../styles/colors";
@import "../../styles/mixins";

.container {
  height: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  @include for-phone-up() {
    width: 100%;
  }
  @include for-desktop-up() {
    width: 80%;
  }

  .card {
    border-radius: 0;
    height: 100%;
    border: none;
    max-height: calc(100vh - 4.7rem);

    .header {
      background-color: #eee;
      border-radius: 0;
      border: none;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
      padding: 0.5rem;
    }

    .addAccordionBody {
      background-color: $scout_grey;
      color: black;
      padding: 0.5rem 1rem;
      box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.25);
    }

    .body {
      flex-grow: 1;
      overflow: auto;
      min-height: 0;

      .title {
        font-weight: bold;
      }
    }

    .footer {
      background-color: $scout_grey;
      border-radius: 0;
      border: none;
      box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.25);
      display: flex;
      flex-direction: row;
      padding: 0.5rem;
      justify-content: flex-end;

      .addButton {
        display: flex;
        width: 2.5rem;
        height: 2.5rem;
        justify-content: center;
        background-color: $scout_red;
        color: $scout_white;
        border-radius: 100%;
        padding-top: 0.8rem;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      }

      .addButton:hover {
        cursor: pointer;
        background-color: #dd3030;
      }
    }
  }
}
