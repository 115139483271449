@import "../../components/card-container/card-container.module.scss";

.notFoundContainer {
  margin-left: auto;
  margin-right: auto;
}

.notFoundBody {
  text-align: center;
}
