@import "../../../styles/colors";
@import "../../../styles/mixins";

.uploadFormContainer {
  margin-top: 1rem;
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.form {
  width: 100%;
  @include for-tablet-landscape-up {
    width: 70%;
    align-items: center;
  }
  @include for-desktop-up {
    width: 60%;
    align-items: center;
  }
  @include for-big-desktop-up {
    width: 50%;
    align-items: center;
  }
}
.input {
  background: white;
  border-radius: 3px;
  border: 1px solid lightgray;
  padding: 0.4rem;
}

.buttonGroup {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}

.infoIcon {
  margin-left: 0.2rem;
  cursor: pointer;
}

.formError {
  margin-top: 1rem;
}
