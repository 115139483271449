@import "../../styles/colors";

.header {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  height: 2.6rem;
  position: absolute;
  width: 100%;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0.3rem;
}

.editTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.editTitleInput {
  height: 2rem !important;
  margin-right: 0.3rem !important;
  margin-left: 0.3rem;
}

.editTitleInput:focus {
  box-shadow: none !important;
}

.editTitleButtonContainer {
  display: flex;
  flex-direction: row;
}

.editTitleSpinnerContainer {
  // The spinner replaces a couple buttons. The buttons had padding (from bootstrap classes), which forced
  // the session title input field to the left of them to be centered vertically. Adding the same padding
  // values to the spinner here so we get the same effect.
  padding: 0.25rem 0.5rem;
}

.editTitleButton {
  margin: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  width: 2.5rem;
  height: 2.5rem;
  color: white !important;
}

.editTitleButton:focus {
  box-shadow: none !important;
}

.cancel {
  color: #dc3545 !important;
}
.cancel:hover {
  background-color: #dc3545 !important;
  color: white !important;
}

.submit {
  color: #28a745 !important;
}
.submit:hover {
  background-color: #28a745 !important;
  color: white !important;
}

.disabled {
  background-color: none !important;
  color: lightgrey !important;
}

.spinner {
  width: 2rem;
  height: 2rem;
}
