@import "../../styles/colors";

.findingView {
  background-color: $scout_grey;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  display: flex;
  flex-direction: row;

  .zoomableImage {
    flex-shrink: 0;
    flex-grow: 1;
    object-fit: contain;
  }

  .imageWarning {
    position: absolute;
    bottom: 5px;
    left: 5px;
    margin: 0;
  }

  .realsenseContainer {
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(-25%);
    display: flex;
    gap: 10px;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    opacity: 0.5;
    transition: opacity 0.3s;
    margin-bottom: 15px;

    &:hover {
      opacity: 1;
    }
  }

  .previewImage {
    width: 100px;
    height: 100px;
    cursor: pointer;
  }
}
