@import "../../styles/colors";
@import "../../styles/mixins";

.container {
  display: flex;
  justify-content: center;
  height: 100%;

  @include for-phone-up() {
    width: 100%;
    flex-direction: column-reverse;
  }
  @include for-tablet-landscape-up() {
    flex-direction: row;
  }
  @include for-desktop-up() {
    width: 80%;
  }
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.titleRow {
  display: flex;
  flex-direction: row;
  background-color: #eee;
  color: black;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  max-height: 2.5rem;
  width: 100%;
  padding-right: 0.5rem;
  z-index: 1;
}

.boxShadow {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

.titleContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  background-color: $scout_black;
  color: $scout_grey;
  height: 2.5rem;
  padding: 0.5rem;
  padding-left: 1rem;
}
.titleContainer:after {
  content: "";
  position: relative;
  width: 0;
  height: 0;
  border-top: solid 2.5rem #eee;
  border-left: solid 2.5rem transparent;
  margin: -0.5rem;
}
.editTitle {
  padding-left: 1rem;
  padding-right: 0.5rem;
  color: red;
}
.editTitle:hover {
  color: $scout_red;
}

.inspectionDescription {
  display: flex;
  min-width: 0;
  height: 2.5rem;
  padding-top: 0.5rem;
  font-style: italic;
}

.sessionContainer {
  // flex-grow: 1;
  margin-top: 0.5rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  background: white;

  @include for-tablet-landscape-up() {
    margin-left: 0.25rem;
    margin-bottom: 0.5rem;
  }
}

.sessionItemContainer {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  gap: 0.25rem;

  @include for-phone-up() {
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: visible;
    width: 100%;
  }

  @include for-tablet-landscape-up() {
    flex-wrap: wrap;
    width: 33.25rem;
    overflow-x: hidden;
  }
}

.findingsContainer {
  flex-grow: 1;
  margin-top: 0.5rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  background: white;
  @include for-tablet-landscape-up() {
    margin-right: 0.25rem;
    margin-bottom: 0.5rem;
  }
}

.toolsContainer {
  margin-top: 0.5rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  background: transparent;
  @include for-tablet-landscape-up() {
    width: 33.25rem;
    height: 8.3125rem;
    margin-left: 0.25rem;
    margin-right: 0;
  }
}

.sessionCount {
  padding: 0.5rem;
  color: black;
}

.contentContainerHeader {
  display: flex;
  flex-direction: row;
  background-color: #eee;
  color: black;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  max-height: 2.5rem;
  width: 100%;
  padding-right: 0.5rem;
  z-index: 1;
  justify-content: space-between;
}
.contentTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: black;
  height: 2.5rem;
  padding-left: 0.5rem;
  min-width: 8rem;
}

.title {
  padding-top: 0.5rem;
  font-weight: bold;
}
.inspectionTitle {
  white-space: nowrap;
}

button.toggleLiveRecordings {
  background: transparent;
  margin-left: 1rem;
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  color: red;
  border-radius: 0;

  &:hover,
  &:focus,
  &:active {
    background: transparent;
    color: red;
    box-shadow: none;
  }
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
}

.actionButton {
  border: 1px solid transparent;
  background-color: $scout_red;
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
  width: 20%;
  @include for-phone-up() {
    width: 25%;
    height: 4rem;
    padding-top: 0.5rem;
  }
  @include for-tablet-landscape-up() {
    width: 8.3125rem;
    height: 8.3125rem;
  }
}

.actionButtonLabel {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.linkAction {
  display: flex;
  justify-content: center;
  background-color: $scout_red;
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
  @include for-phone-up() {
    width: 25%;
    height: 4rem;
  }
  @include for-tablet-landscape-up() {
    width: 8.25rem;
    height: 8.25rem;
  }
}
.linkActionButton {
  border: none;
  background: transparent;
  color: white;
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
}

.linkAction:hover,
.actionButton:hover {
  background-color: #902020;
  text-decoration: none;
  color: white;
  border: 1px solid $scout_darkgrey;
}
.darkActionButton {
  background-color: #dd3030;
}

.formAccordion {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #eee;
  color: $scout_white;
  padding: 0.5rem 1rem;
}

.tileIcon {
  color: white;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.toolsAndSessionPanel {
  display: flex;
  flex-direction: column;
}
