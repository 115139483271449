.dygraph-legend-line {
  display: inline-block;
  position: relative;
  bottom: 0.5ex;
  padding-left: 1em;
  height: 1px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.dygraph-label.dygraph-xlabel {
  transform: translate(50%, -15%);
  color: white;
}

.dygraph-label.dygraph-ylabel {
  transform: rotate(-90deg) translate(50%, 50%);
  color: white;
}
