.theImage {
  object-fit: contain;
  width: 100% !important;
  height: 100% !important;
}

.theWrapper {
  width: 100% !important;
  height: 100% !important;
}

.theZoomContent {
  width: 100% !important;
  height: 100% !important;
}
