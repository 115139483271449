@import "../../../styles/colors";
@import "../../../styles/mixins";

.container {
  width: 100%;
  margin: 1rem auto;
  font-size: medium;
  @include for-tablet-landscape-up {
    width: 70%;
  }
  @include for-desktop-up {
    width: 60%;
  }
  @include for-big-desktop-up {
    width: 50%;
  }
}

.howDetails {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.howDetails summary {
  font-weight: bold;
}

.folderSelect {
  border: 1px solid $scout_black;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  padding: 0.5rem;
}

.uploadResultsText {
  border: 1px solid green;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  padding: 0.5rem;
  text-align: center;
}

.sessionsInfo {
  margin: 2rem;
  font-weight: bold;
  text-align: center;
}

#sessionTable {
  width: 100%;
  margin: 1rem auto;
}

.headerCell {
  text-align: center;
  white-space: nowrap;
  text-overflow: clip;
  overflow: hidden;
  @include for-phone-only() {
    max-width: 3.5rem;
  }
}

.noOverflowCell {
  width: 0;
  white-space: nowrap;
  text-align: center;
}

.ellipsisCell {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 1px;
  text-align: left;
}

.leftAlign {
  text-align: left;
}

.buttonGroup {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  margin-top: 2rem;
}
