.login-page {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  color: white;
}

.login-page > h4,
.login-page > button {
  margin-top: 10vh;
}
