@import "../../styles/colors";

.heatmapContainer {
  visibility: inherit !important;
  width: 100%;
  height: 100%;
  background-color: $scout_black;
  display: flex;
  position: absolute;
  overflow: hidden;

  &__branding {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.63);

    &__text {
      align-self: flex-end;
      margin: 0 0 2px;
      font-size: 6.5pt;
    }
    &__logo {
      align-self: flex-end;
      max-width: 100px;
    }
  }

  &__heatmap {
    position: relative;
    flex: 1;
    overflow: hidden;
    height: 100%;
    border-right: 1px solid $scout_white;

    &__dygraph {
      // !important is needed to overwrite the inline style that is
      // sometimes applied by dygraph
      width: 100% !important;
      height: 100% !important;
    }

    &__chartArea {
      pointer-events: none;
      position: absolute;
      top: 0;
      right: 0;

      &__bar {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        border-right: 2px solid $scout_white;
        background-color: rgba(230, 230, 230, 0.3);
      }
    }
  }

  &__sidebar {
    //border-left: 2px solid $scout_white;
    right: 0;

    &__labels {
      padding-left: 10px;
      padding-right: 10px;
      white-space: nowrap;
      color: $scout_white;
    }
  }

  &__spinner_container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    &__text {
      color: $scout_white;
    }
  }
}
