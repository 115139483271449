@import "../../styles/colors";

.video-overlay {
  &__branding {
    position: absolute;
    top: 50px;
    right: 10px;
    display: flex;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.63);
    &__text {
      align-self: flex-end;
      margin: 0 0 2px;
      font-size: 6.5pt;
    }
    &__logo {
      align-self: flex-end;
      max-height: 34px;
    }
  }
  &__box {
    background-color: transparent;
    border: 3px solid $scout_red;
    position: absolute;
    &__label {
      position: relative;
      top: -20px;
      width: 100%;
      font-size: 12px;
      background-color: $scout_red;
    }
  }
}
