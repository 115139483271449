@import "../../styles/colors";
@import "../../styles/mixins";

.session-card {
  position: relative;
  display: flex;
  flex-flow: column;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 16rem;
  flex-shrink: 0; // do not shrink
  border: 1px solid darkgrey;

  &__lastSelected {
    outline: 2px solid $scout_blue;
  }
}
