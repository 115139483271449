@import "../../styles/colors";

.absoluteBottomPosition {
  position: absolute;
  bottom: 0px;
}

.onHoverThumbnail {
  border: 2px solid $scout_darkgrey;
  border-radius: 9px;
  max-width: 250px;
  max-height: 250px;
  height: auto;
  width: auto;
  animation: fadeinout 4s linear 1 forwards;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.fadeIn {
  animation: fadeinout 4s linear 1 forwards;
  visibility: visible;
  opacity: 1;
  transition:
    visibility 0s linear 0s,
    opacity 300ms;
}

.fadeOut {
  animation: fadeinout 4s linear 1 forwards;
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s linear 300ms,
    opacity 300ms;
}
