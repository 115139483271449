@import "../../styles/colors";
@import "../../styles/mixins";

.emptyLive {
  padding-top: 5vh;
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.emptyLive .btn {
  margin-top: 10vh;
  width: 33vw;
}

.live {
  display: flex;
  flex-flow: column;
  background-color: $scout_white;

  &__header {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    background: $scout_grey;
    padding: 0.2rem 1rem 1rem 1rem;

    &__title {
      font-weight: bold;
    }
  }

  &__target {
    margin-top: 1rem;
    padding: 1rem;

    &__title {
      h2 {
        display: inline;
      }

      h6 {
        display: inline;
      }
    }

    &__content {
      display: flex;
      flex-flow: row;
      @include lt-md() {
        flex-flow: column;
      }

      &__cards {
        display: flex;
        flex-flow: row wrap;
      }

      &__sessions {
        flex: auto;

        &__title {
          display: flex;
          flex-flow: row;
          justify-content: space-between;

          &__count {
            font-weight: bold;
            font-size: 12px;
            line-height: 30px;
            align-self: flex-end;
          }
        }

        &__cards {
          display: flex;
          flex-flow: row wrap;
        }
      }
    }
  }
}
