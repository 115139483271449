@import "../../styles/colors";

@media screen and (min-width: 801px) {
  .finding_data_panel {
    display: flex;
    flex-direction: column;
  }
}

.finding_data_panel {
  width: 400px;
  background-color: $scout_grey;
  padding: 5px;
  max-width: 300px;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .finding_data_panel {
    display: none;
  }
}

.finding_subtitle {
  width: 100%;
  background-color: $scout_darkgrey;
  padding: 5px;
  color: $scout_grey;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.finding_subtitle h6 {
  flex: auto;
  align-self: center;
  margin: 0;
  user-select: none;
}

.finding_subtitle span {
  width: 25px;
  height: 100%;
}

.finding_section_content {
  padding: 5px 0 5px 0;
}

.lights_info {
  width: 100%;
  border-radius: 5px;
  background-color: $scout_dark_black;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.lights_panel {
  display: flex;
  flex-direction: row;

  div {
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    h6 {
      padding: 5px 0;
      color: white;
      font-size: 9pt;
      margin: 0;
    }
  }
}

table.position_table {
  $table_border: 1px solid $divider_color;
  border: $table_border;
  border-radius: 5px;
  border-collapse: separate;
  border-spacing: 0;

  tr {
    td {
      white-space: nowrap;
      border-bottom: $table_border;
      padding: 5px;
      border-right: $table_border;
    }

    td:first-child {
      text-align: center;
      color: $scout_darkgrey;
    }

    td:last-child {
      border-right: none;
      width: 100%;
    }
  }

  tr:last-child {
    border-bottom: none;

    td {
      border-bottom: none;
    }
  }
}

.selector {
  display: flex;
  flex-direction: row;
  align-content: center;

  span {
    padding-right: 10px;
    padding-left: 10px;
    display: block;
  }

  select {
    flex: auto;
  }
}

.finding_actions {
  display: flex;
  flex-direction: column;

  div {
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    flex: auto;

    button {
      flex-grow: 1;
    }

    button:nth-child(even) {
      // add margin between buttons
      margin-left: 5px;
    }
  }
}

.modal_actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-self: flex-end;

  button {
    margin-top: 5px;
  }

  button:first-child {
    margin-right: 2px;
  }

  button:last-child {
    margin-left: 2px;
  }
}
