$scout_black: #323232;
$scout_dark_black: #1e2329;
$scout_darkgrey: #6c757d;
$scout_grey: #e6e6e6;
$scout_red: #ef4036;
$scout_blue: #007bff;
$scout_blue_washed: #b5d9ff;
$scout_white: #fdfdfd;

// Divider
$divider_color: lighten($scout_darkgrey, 20%);

// Graph
$graph_low: #99bcff;
$graph_medium: #ffa34f;
$graph_high: #f51d00;
