@import "../../styles/colors";
.createNewReportContainer {
  display: flex;
  justify-content: flex-end;
  width: 2.5rem;
  position: absolute;
  right: 0.3rem;
  bottom: 0.3rem;
}

.createNewReportAccordion {
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  justify-content: center;
  background-color: $scout_red;
  color: $scout_white;
  border-radius: 100%;
  padding-top: 0.8rem;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}
.createNewReportAccordion:hover {
  cursor: pointer;
}

.createNewReportAccordionBody {
  background-color: $scout_black;
  color: $scout_white;
  padding: 0.5rem 1rem;
}
.templateDropDown {
  color: black;
}

div.actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 0.3rem;
}

.boldText {
  white-space: nowrap;
  min-width: 4.8rem;
  opacity: 0.5;
}

.timeStamp {
  display: flex;
  flex-direction: row;
}

div.reportItem {
  display: flex;
  flex-direction: row;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.1rem;
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.downloadLoading {
  display: flex;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
}

button.actionButton {
  margin: 0;
  border: none;
  width: 2rem;
  height: 2rem;
}

button.deleteButton {
  color: $scout_red;
}

button.deleteButton:hover {
  color: $scout_white;
  background-color: $scout_red;
}

button.downloadButton {
  color: $scout_blue;
}

button.downloadButton:hover {
  color: $scout_white;
  background-color: $scout_blue;
}

.addOrEditLink {
  padding-top: 2rem;
  justify-content: flex-end;
}

.addOrEditLink > a {
  color: $scout_white;
}

.addOrEditLink > a:hover {
  color: $scout_red;
}

.flexRow {
  display: flex !important;
  flex-direction: row !important;
}

.flexColumn {
  display: flex !important;
  flex-direction: column !important;
}

.noReportsToShowMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
