@import "../../styles/colors";
@import "../../styles/mixins";

.findings-sidebar {
  height: 100%;
  width: 30%;
  max-width: 410px;
  position: fixed;
  z-index: 25;
  background-color: $scout_grey;
  right: 0;
  overflow-x: hidden;
  padding-top: 10px;
  transition: 0.5s;

  @include lt-md {
    padding: 0;
    position: absolute;
    bottom: 0px;
    width: 100%;
    max-width: none;
    height: 30%;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    h4 {
      margin: 0;
      flex-grow: 1;
      text-align: start;
    }
    &__close {
      font-size: 30px;
      font-weight: bold;
      color: $scout_darkgrey;
      background-color: $scout_grey;
    }
    &__text {
      width: 100%;
      display: inline-flex;
      justify-content: center;
      @include lt-md {
        h1 {
          font-size: 24px;
        }
      }
    }
  }

  &__gallery {
    display: flex;
    flex-flow: column nowrap;
  }
}
