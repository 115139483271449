@import "../../styles/colors";
@import "../../styles/mixins";

.sidebar-button-group {
  position: absolute;
  z-index: 20;
  bottom: 6em;
  right: 1em;
  display: flex;
  flex-direction: column;

  &__bottom-of-screen {
    @extend .sidebar-button-group;
    flex-direction: row;
    right: unset;
    bottom: 0;
  }
}

.sidebar-button-group.div {
  margin-bottom: 5px;
}

.sidebar-trigger-button {
  background-color: $scout_grey;
  color: $scout_black;
  display: block;
  text-align: center;
  cursor: pointer;
  border: 2px solid $scout_darkgrey;
  width: 50px;
  height: 50px;
  margin: 2px;
  font-size: 30px;
  border-radius: 20%;
  position: relative;

  padding-inline: 6px;
  padding-block: 1px;

  @include lt-md {
    font-size: 20px;
    width: 40px;
    height: 40px;
    bottom: 0;
  }

  &:hover {
    background-color: $scout_blue;
    color: $scout_white;
  }

  .badge {
    position: absolute;
    padding: 0;
    left: -6px;
    font-family: sans-serif;
    border-radius: 1px;
    width: 18px;
    line-height: 1.3;
    height: 15px;
    font-size: 13px;

    &__large {
      width: auto;
      padding: 1px;
    }
  }

  &:disabled {
    background-color: $divider_color;
    color: $scout_darkgrey;
  }
}
.sidebar-button__exclamation {
  margin: 0 6px;
}

.sidebar-button-inverted {
  background-color: $scout_blue;
  color: $scout_white;
}

.fa-stack {
  font-size: 0.5em;
}
.fa-stack:hover > .fa-arrows-h {
  color: $scout_blue;
}
