@import "../../styles/colors";

:global(.scoutTemplate):global(.modal-dialog).modal {
  max-width: fit-content;
}

:global(.scoutTemplate) {
  :global(.modal-content).modalContent {
    border: 1px solid $scout_white;
  }
}

:global(.modal-header).modalHeader {
  border: 0;

  h5 {
    width: 100%;
    text-align: center;
  }
}

:global(.modal-body).modalBody {
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  background-color: $scout_dark_black;
}

.visualizationChoice {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  color: $scout_white;
  width: 150px;

  .imageButton {
    padding: 0;
    margin: 0 0 0.5rem;
    border: 0;

    img {
      width: 100%;
      object-fit: cover;
    }

    img:hover {
      opacity: 0.8;
    }
  }

  .imageButton:focus {
    box-shadow: none;
  }

  // We have to be quite specific in order to override the bootstrap CSS in this case...
  :global(.btn):global(.btn-secondary).imageButton:focus:active {
    box-shadow: none;
  }

  p {
    text-align: center;
  }

  .extraText {
    display: none;
  }

  @media only screen and (min-width: 768px) {
    .extraText {
      display: inline;
    }
  }
}

@media only screen and (min-width: 600px) {
  .visualizationChoice {
    width: 250px;
  }
}

@media only screen and (min-width: 768px) {
  .visualizationChoice {
    width: 300px;
  }
}

@media only screen and (min-width: 992px) {
  .visualizationChoice {
    width: 500px;
  }
}
