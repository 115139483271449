.application-modal {
  &__content {
    &__table {
      th,
      td {
        padding: 15px;
        text-align: left;
      }
    }
  }
  &__credentials {
    &__table {
      margin-top: 3.5em;
      &__header {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 0.2em;
      }
      &__content {
        overflow-x: auto;
      }
    }
  }
}
