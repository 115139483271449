@import "../../styles/colors";

.add-finding-knob {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 8rem;
  height: 8rem;
  border: 0;
  background-color: $scout_grey;

  &__button.btn {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: $scout-black;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 0;
  }

  &__button {
    &__text {
      margin: 0;
    }
  }
}
