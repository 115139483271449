@import "../../styles/colors";
@import "../../styles/mixins";

.filter {
  width: 100%;
  padding: 0 0.2rem;
}

.filterLabel {
  color: $scout_darkgrey;
  font-weight: 100;
  font-size: small;
  margin: 0;
}

.filterContainer {
  display: flex;
  flex-direction: column;
  background: rgb(238, 238, 238);
  padding: 0 1rem 0.5rem 1rem;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  min-height: 4.5rem;
}

.filterRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

.filterColumn {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

.createNewInspectionContainer {
  width: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 0.3rem;
}

.createNewInspectionAccordion {
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  justify-content: center;
  background-color: $scout_red;
  color: $scout_white;
  border-radius: 100%;
  padding-top: 0.8rem;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
}
.createNewInspectionAccordion:hover {
  cursor: pointer;
  background-color: #dd3030;
}
