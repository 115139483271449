@import "../../styles/colors";

.measurement_container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;

  &.measurement_selected {
    // rounded border
    border: 1px solid $scout_red;
    border-radius: 3px;
    background-color: rgba(255, 0, 0, 0.1);
  }

  .measurement_no_ascan {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: $divider_color;
    background-color: $scout_dark_black;
  }

  .dark_mode {
    color: white;
  }

  .measurement_header {
    // make the text all caps
    text-transform: uppercase;
    align-self: center;
    color: white;
  }

  .measurement_timespan_header {
    padding-left: 10px;
    align-self: center;
    // dark grey
    color: $scout_darkgrey;
  }

  .box_chart_container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-grow: 1;
    padding: 5px;
  }

  .position_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 5px;
  }
}

table.measurement_position_table {
  $table_border: 1px solid $divider_color;
  border: $table_border;
  border-radius: 5px;
  border-collapse: separate;
  border-spacing: 0;
  color: white;

  tr {
    td {
      white-space: nowrap;
      border-bottom: $table_border;
      padding: 5px;
      border-right: $table_border;
    }

    td:first-child {
      text-align: center;
      color: $scout_darkgrey;
    }

    td:last-child {
      border-right: none;
      width: 100%;
    }
  }

  tr:last-child {
    border-bottom: none;

    td {
      border-bottom: none;
    }
  }
}
