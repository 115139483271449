@import "../../styles/colors";
@import "../../styles/mixins";
@import "../../components/card-container/card-container.module.scss";

.sessionVisualizations {
  background-color: $scout_white;

  &Header {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    background: $scout_grey;
    padding: 0.2rem 1rem 1rem 1rem;

    &Title {
      font-weight: bold;
    }

    &ButtonGroup {
      display: flex;
      flex-flow: row;
      justify-content: left;

      &GenerateVisualizationDisabled {
        pointer-events: none;
      }
    }
  }

  &Content {
    margin-top: 2rem;
    padding: 1rem;
    width: 100%;

    &Table {
      width: 100%;
      margin: 0 auto 100px;
    }
  }

  &NavigationButtons {
    margin-top: 3rem;
    display: flex;
    flex-flow: row;
    justify-content: center;
  }
}
