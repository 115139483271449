@import "../../components/card-container/card-container.module.scss";
@import "../../styles/colors";

.boldText {
  white-space: nowrap;
  min-width: 5.8rem;
  opacity: 0.5;
}

div.templateItem {
  position: relative;
  display: flex;
  flex-direction: row;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.1rem;
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

div.defaultTemplate {
}

div.templateItem:hover and :not(.defaultTemplate) {
  cursor: pointer;
  background-color: #fffafa;
}

.activeItem {
  background-color: #fffafa;
}

.flexRow {
  display: flex !important;
  flex-direction: row !important;
}

.flexColumn {
  display: flex !important;
  flex-direction: column !important;
}

.noTemplatesToShowMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.iconContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icon {
  font-size: 1.5rem;
  padding-right: 0.5rem;
}
