@import "../../styles/colors";

.videoButtonGroup {
  position: absolute;
  z-index: 10;
  bottom: 3em;
  right: 1em;
  display: flex;
  flex-direction: row;
}

.videoButton {
  background-color: $scout_black;
  text-align: center;
  cursor: pointer;
  border: 2px solid $scout_black;
  color: $scout_grey;
  font-size: 20px;
  z-index: 10;

  &:hover {
    background-color: $scout_blue;
    color: $scout_white;
  }
}
