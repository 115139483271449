@import "~bootstrap/scss/bootstrap";

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: $nav-link-padding-y $nav-link-padding-x;
  text-decoration: if($link-decoration == none, null, none);

  @include hover-focus() {
    text-decoration: none;
  }

  // Disabled state lightens text
  &.disabled {
    color: $nav-link-disabled-color;
    pointer-events: none;
    cursor: default;
  }
}

.nav-tabs {
  border-bottom: $nav-tabs-border-width + 1px solid $nav-tabs-border-color;

  .nav-link {
    border: $nav-tabs-border-width solid $nav-tabs-border-color;
    border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
    @include border-top-radius($nav-tabs-border-radius);
    margin-bottom: -$nav-tabs-border-width - 2px;
    color: $nav-tabs-link-active-color;
    cursor: pointer;

    @include hover-focus() {
      border-color: $nav-tabs-link-hover-border-color;
    }
    &.disabled {
      color: $nav-link-disabled-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $nav-tabs-link-active-color;
    background-color: $nav-tabs-link-active-bg;
    border-color: $nav-tabs-link-active-border-color;
    border-width: $nav-tabs-border-width + 1px;
    font-weight: bold;
    margin-bottom: -$nav-tabs-border-width - 1px;
    cursor: default;
  }
}
