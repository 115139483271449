@import "../../styles/colors";
@import "../../styles/mixins";

.applications {
  display: flex;
  flex-flow: column;
  background-color: $scout_white;

  &__header {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    background: $scout_grey;
    padding: 0.2rem 1rem 1rem 1rem;

    &__title {
      font-weight: bold;
    }
  }

  &__content {
    display: flex;
    flex-flow: column;
    margin-top: 1rem;
    padding: 1rem;
    overflow-x: auto;
    &__link {
      color: $scout_blue;
      transition: 0.2s all;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: #0056b3;
      }
    }
  }
}
