@import "../styles/mixins";

.route-container {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
}
.page {
  height: 100%;
  display: flex;
  flex-direction: column;
}
