@import "../../styles/colors";

.inputSection {
  display: flex;
  align-items: stretch;
  background-color: $scout_black;
  border: none;
  border-radius: 5px;
}

.inputSection input {
  padding: 10px;
  color: white;
  background: transparent;
  border: 0;
  outline: none;
}

.inputSection button {
  margin: 0;
}

.inputSection input::placeholder {
  color: $scout_white;
}

.inputSection a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  color: white;
  font-size: 12px;
  background-color: #3b5bdb;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  transition:
    background-color 0.5s,
    color 0.5s;
}

.inputSection a:hover,
.inputSection a:active {
  color: #3384ff;
  background-color: #5c7cfa;
}
