@import "../styles/colors";

.loading-init {
  height: 100%;
  background-color: $scout_grey;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
