@import "../../styles/colors";

.verticalDivider {
  position: absolute;
  left: 50%;
  top: 5%;
  bottom: 5%;
  border-left: 1px solid;
  border-color: $scout_white;
}

.horizontalDivider {
  position: absolute;
  top: 50%;
  left: 5%;
  right: 5%;
  border-bottom: 1px solid;
  border-color: $scout_white;
}

.container {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 20rem;
  min-height: 10rem;
  font-size: 0.8rem;
}

.rowContainer {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: $scout_white;
}

.columnContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: space-between;
  color: $scout_white;
}

.pane {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  font-size: 1.5vh;
}

.paneElementContainer {
  padding-top: 0.5rem;
  position: relative;
  width: 100%;
  height: 100%;
}

.paneTitle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20%;
  color: $scout_white;
  text-decoration: underline;
  font-size: 2vh;
}

.lightInfo {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 50%;
  height: 100%;
  left: 25%;
  color: $scout_white;
  //font-size: 1.5vh;
  align-content: space-between;
}

.singleLight {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
  margin: 0 -1em;
}

.cameraIcon {
  padding-top: 0.5rem;
  font-size: 4vh;
}

.droneContainer {
  position: relative;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  height: 100%;
  min-width: 10%;
  font-size: 1.5vh;
}

.droneIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 7vh;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.upContainer {
  position: absolute;
  left: 50%;
  bottom: calc(50% + 4vh);
  height: 25%;
  aspect-ratio: 1;
}

.upText {
  white-space: nowrap;
  position: absolute;
  left: 10%;
  bottom: 1rem;
}

.rightContainer {
  position: absolute;
  top: 50%;
  left: calc(50% + 7vh);
  width: 25%;
  aspect-ratio: 1;
}

.rightText {
  white-space: nowrap;
  position: absolute;
  bottom: 105%;
}

.downContainer {
  position: absolute;
  left: 50%;
  top: calc(50% + 2rem);
  height: 25%;
  aspect-ratio: 1;
}

.downText {
  white-space: nowrap;
  position: absolute;
  left: 10%;
  top: 1rem;
}

.arrow {
  position: relative;
  height: 0.15rem;
  left: 0;
  width: 100%;
  max-width: 6vh;
  background: $scout_white;
}

.arrow:after {
  position: absolute;
  content: "";
  right: -2px;
  transform: translateY(-43%);
  border-top: 0.5em solid transparent;
  border-bottom: 0.5em solid transparent;
  border-left: 0.5em solid $scout_white;
}

.upArrow {
  position: absolute;
  bottom: 0;
  transform: translateX(-50%) rotate(-90deg) translateX(50%);
}

.downArrow {
  transform: translateX(-50%) rotate(90deg) translateX(50%);
}
