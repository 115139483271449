@import "../../styles/colors";
@import "../../styles/mixins";
@import "../../components/card-container/card-container.module.scss";

.boldText {
  white-space: nowrap;
  min-width: 5.8rem;
  opacity: 0.5;
}

.timeStamp {
  display: flex;
  flex-direction: row;
}

div.reportItem {
  display: flex;
  flex-direction: row;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.1rem;
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

div.reportItem:hover {
  cursor: pointer;
  background-color: #fffafa;
}

.activeItem {
  background-color: #fffafa;
}

.downloadLoading {
  display: flex;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
}

button.actionButton {
  margin: 0;
  border: none;
  width: 2rem;
  height: 2rem;
}

button.deleteButton {
  color: $scout_red;
}

button.deleteButton:hover {
  color: $scout_white;
  background-color: $scout_red;
}

button.downloadButton {
  color: $scout_blue;
}

button.downloadButton:hover {
  color: $scout_white;
  background-color: $scout_blue;
}

.addOrEditLink {
  padding-top: 2rem;
  justify-content: flex-end;
}

.addOrEditLink > a {
  color: $scout_white;
}

.addOrEditLink > a:hover {
  color: $scout_red;
}

.flexRow {
  display: flex !important;
  flex-direction: row !important;
}

.flexColumn {
  display: flex !important;
  flex-direction: column !important;
}

.noReportsToShowMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
