@import "../../styles/colors";

.new-video-analysis {
  display: flex;
  flex-flow: column;
  background-color: $scout_white;

  &__header {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    background: $scout_grey;
    padding: 0.2rem 1rem 1rem 1rem;

    &__title {
      font-weight: bold;
    }
  }

  &__tab {
    width: 100%;
    margin-top: 40px;

    &__header {
      text-align: center;
    }

    &__fileformat {
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-content: center;
      margin-top: 20px;

      &__label {
        margin-right: 20px;
        margin-left: 40px;
      }
      &__radio {
      }
    }

    &__info {
      text-align: center;
      color: red;
      display: inline-block;
      width: inherit;
      margin-top: 10px;
    }
  }
}

.nav-item {
  cursor: pointer;
}

.active-tab {
  border-color: $scout_darkgrey $scout_darkgrey $scout_white !important;
}

.nav-tabs {
  border-bottom-color: $scout_darkgrey !important;
}
