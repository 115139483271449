@import "../../styles/colors";

.filterAndGroupRow {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  background-color: #f7f7f7;
}

.findings {
  display: flex;
  padding: 0.5rem;
  transition: height 2s;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.header {
  display: flex;
  background-color: $scout_darkgrey;
  color: white;
  margin: 0.5rem 0.5rem 0 0.5rem;
}

.headerItem {
  position: relative;
  top: 0.4rem;
  left: 0.4rem;
  margin-right: 0.4rem;
}
