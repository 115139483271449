@import "../../styles/colors";

.uploadContainer {
  color: $scout_darkgrey;
  font-weight: 100;
  font-size: small;
}
.UploadModeSelectorContainer {
  text-align: center;
}

.title {
  font-size: 1rem;
  text-decoration: underline 1px solid $scout_dark_black;
  color: $scout_dark_black;
}
