@import "../../styles/colors";

.session-card-footer-invisible {
  visibility: hidden;
}

.session-card {
  &__footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    flex-flow: row;
    padding: 0.3rem;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;

    &__findings-section {
      display: flex;
      flex-flow: column;
      justify-content: space-evenly;
      align-self: center;

      &__data {
        display: flex;
        flex-flow: row;

        &__count {
          background-color: $scout_blue;
          border-radius: 3px;
          color: $scout_white;
          font-size: 15px;
          line-height: 1;
          padding: 0.3rem 0.5rem;
        }

        &__graph {
          display: flex;
          flex-flow: row;
          margin-left: 5px;
          align-items: flex-end;

          > div {
            width: 5px;
            margin-right: 2px;
          }

          #low {
            background-color: $graph_low;
          }

          #medium {
            background-color: $graph_medium;
          }

          #high {
            background-color: $graph_high;
          }
        }
      }

      &__title {
        align-self: center;
        font-size: 10px;
      }
    }

    &__datetime-section {
      display: flex;
      flex-flow: column;
      flex: 2;

      &__time-ago {
        font-size: 14px;
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
      }

      &__date {
        font-size: 12px;
      }

      &__timespan {
        font-size: 10px;
      }
    }

    &__menu {
      &__link {
        &:hover {
          text-decoration: none;
        }
      }
      .dropdown-item {
        color: $scout_darkgrey;
        &:hover {
          color: $scout_blue;
        }
      }

      .btn-secondary {
        background-color: $scout_darkgrey;
        border: none;
        border-radius: 0;
        padding: 0 0.5rem;
        margin-top: 0.25rem;
        margin-bottom: 0.5rem;

        &:focus {
          box-shadow: none;
        }
      }

      .fa-ellipsis-v {
        color: $scout_dark_black;
      }
    }
  }
}
