@import "../../styles/colors";

.companyBrand {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  height: 2em;
  width: auto !important; //override NavBarBrand default width
}

.poweredBy {
  color: $scout-white;
  font-size: 0.5em;
  font-style: italic;
  align-items: center;
  display: flex;
  gap: 1ch;
}

.scoutLogo {
  -webkit-mask: url(../../assets/images/logo_without_badge.svg) no-repeat center / contain;
}

.img {
  visibility: hidden;
  height: 1.5em;
  width: auto !important; //override NavBarBrand default width
}
