.vjs-fbf {
  border: 1px solid white;
  padding: 2px 3px;
  border-radius: 2px;
}

/* CSS Grid - Clip toolbar - mobile */
@media only screen and (max-width: 576px) {
  .video-js .vjs-control {
    width: 2.5em;
  }
}
