@import "../../styles/colors";

.session-shared {
  background-color: $scout_grey;
  height: 100%;

  display: flex;
  flex-flow: column;

  justify-content: center;
  align-items: center;

  &__alert {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}
