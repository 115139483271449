@import "../../styles/colors";

.video-analyses {
  background-color: $scout_white;
  &__header {
    &__title {
      font-weight: bold;
    }
    &__buttongroup {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      &__predictions {
      }
    }
  }
  &__content {
    margin-top: 1rem;
    width: 100%;

    &__processing-table {
      width: 70%;
      border: 1px solid $scout_darkgrey;
      border-radius: 10px;
      margin: 0 auto 100px;

      &__header {
        margin: 1rem 0.3rem 1.5rem;
      }
    }
  }
  &__button-group {
    margin-top: 3rem;
    display: flex;
    flex-flow: row;
    justify-content: center;
  }
}

.dev-options {
  margin-top: 10px;
}

.prediction-status-modal {
  &__body {
    &__info {
      &__steps {
        margin: 2em 0;
        &__name {
          font-size: 20px;
        }
      }
      &__batches {
        &__header {
          font-size: 18px;
          text-align: center;
        }
      }
    }
    .loading-true {
      color: $scout_grey;
    }

    &__loading {
      position: absolute;
      left: 45%;
    }

    &__subheader {
      text-align: center;
      font-size: 24px;
      margin: 1em 1em;
    }
  }
}
