@import "../../styles/colors";
@import "../../styles/mixins";
@import "../../components/card-container/card-container.module.scss";

.visualizationUpload {
  display: flex;
  flex-flow: column;
  background-color: $scout_white;

  .headerTitle {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    background: $scout_grey;
    padding: 0.2rem 1rem 1rem 1rem;
    font-weight: bold;
  }

  .buttonGroup {
  }

  &Content {
    margin-top: 1rem;
    padding: 1rem;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    &Title {
      margin-bottom: 2rem;
    }
  }
}

.uploadIndicator {
  width: 75%;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;

  hr {
    width: 100%;
  }

  span {
    width: fit-content;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  canvas {
    margin-top: 1rem;
    margin-bottom: 5rem;
  }
}

.progressbar {
  width: 100%;
}
