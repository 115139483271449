@import "breakpoints";

// less than small
@mixin lt-sm {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

// less than medium
@mixin lt-md {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lt-lg {
  @media (max-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin lt-xl {
  @media (max-width: #{$screen-xl-min}) {
    @content;
  }
}

// greater than small
@mixin gt-sm {
  @media (min-width: #{$screen-sm-max}) {
    @content;
  }
}

// greater than medium
@mixin gt-md {
  @media (min-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin gt-lg {
  @media (min-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin gt-xl {
  @media (min-width: #{$screen-xl-max}) {
    @content;
  }
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-phone-up {
  @media (min-width: 0px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}
