@import "../../styles/colors";

.metadataContainer {
  position: absolute;
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  background-color: rgba(256, 256, 256, 0.5);
  top: 0;
  font-size: 16px;
  z-index: 5;
  cursor: default;
  padding: 0.25rem;
}

.metadataElement {
  color: black;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.metadataContainerLogo {
  height: 28px;
}
.additionalDataContainer {
  display: grid;
  grid-template-columns: auto auto;
  color: black;
}

.metadataIcon {
  margin-left: 0.5rem;
}

.metadataText {
  display: inline;
  margin-left: 0.25rem;
}
