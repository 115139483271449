@import "../../styles/colors";
@import "../../styles/mixins";
@import "../../components/card-container/card-container.module.scss";

.termsOfUseContainer {
  @extend .container;

  .card {
    @extend .container, .card;
    max-height: unset;
  }
}
