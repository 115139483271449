@import "../../styles/colors";
@import "../../styles/mixins";

.player-container {
  background-color: #323232;
  height: 100%;
  width: 100%;

  &__back-button {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2) !important;
    border-color: lighten($scout_darkgrey, 10%) !important;
    border-width: 1px !important;
    border-style: solid !important;
    color: lighten($scout_darkgrey, 10%) !important;

    @include lt-sm {
      font-size: 12px !important;
      width: 60px;
      padding: 2px !important;
    }

    &:hover {
      color: $scout_white !important;
    }
  }
}

.player-container-live {
  @extend .player-container;
  .vjs-tech {
    pointer-events: none;
  }
}

#video-element {
  height: 100%;
  width: 100%;
}

.root-container__container____RXkJ {
  //height: 100vh !important;
}

.videoplayerHeader {
  position: fixed;
  top: 3em;
  left: 2%;
  margin-top: 0.5%;
  margin-right: 0.5%;
  display: flex;
  z-index: 10;
}

.liveSignal {
  width: 7rem;
  color: red;
  border: 1px solid red;
  font-size: 1.5em;
  margin-left: 4%;
  padding-left: 4%;
  padding-right: 4%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.liveSignal i {
  margin-right: 5px;
}

.vjs-custom .vjs-big-play-button {
  display: none !important;
}

// Some of the elements in the videojs control-bar are hidden by default,
// so we need to override that.
div .video-js {
  .vjs-current-time,
  .vjs-duration,
  .vjs-time-divider {
    display: block;
  }
}

// The selector will be active on the control-bar when it is
// supposed to fade out. By setting this to opacity 1, we override it.
div .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  opacity: 1;
}

// This forces the videojs control-bar to be visible when the videojs player
// is initialized.
div .video-js .vjs-control-bar {
  display: flex;
  opacity: 1;
  height: 2rem;
}
