@import "../../styles/colors";
@import "../../styles/mixins";

.imageToggleButton {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  background: $scout_grey;
  cursor: pointer;
  color: white;
  padding: 0;
  border: 0.15rem solid transparent;
  margin-right: 1px;
}

.imageToggleButton::before,
.imageToggleButton::after,
.imageToggleButton > div::before,
.imageToggleButton > div::after {
  display: block;
  content: "";
  width: 0.25rem;
  height: 0.25rem;
  position: absolute;
}

.imageToggleButton::before {
  top: -0.3rem;
  left: -0.3rem;
  border-top: 1.5px solid white;
  border-left: 1.5px solid white;
  transition: 0.4s all;
}
.imageToggleButton::after {
  top: -0.3rem;
  right: -0.3rem;
  border-top: 1.5px solid white;
  border-right: 1.5px solid white;
  transition: 0.4s all;
}
.imageToggleButton > div::before {
  bottom: -0.3rem;
  left: -0.3rem;
  border-bottom: 1.5px solid white;
  border-left: 1.5px solid white;
  transition: 0.4s all;
}
.imageToggleButton > div::after {
  bottom: -0.3rem;
  right: -0.3rem;
  border-bottom: 1.5px solid white;
  border-right: 1.5px solid white;
  transition: 0.4s all;
}

.imageToggleButton:hover::before,
.imageToggleButton:hover::after {
  width: 1rem;
  height: 1rem;
}

.imageToggleButton:hover div::before,
.imageToggleButton:hover div::after {
  width: 1rem;
  height: 1rem;
}

.imageToggleButton > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: $scout_dark_black;
  border-radius: 100%;
}

.imageToggleButton > div > i {
  font-size: 0.55rem;
}

.flexRow {
  display: flex !important;
  flex-direction: row !important;
}

.flexColumn {
  display: flex !important;
  flex-direction: column !important;
}

.flexEnd {
  justify-content: flex-end;
}

.accordionContainer {
  position: absolute;
  width: 100%;
  z-index: 9999;
}

.accordion {
  background-color: $scout_white;
  color: white;
  width: 15rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;

  @include lt-md {
    background-color: $scout_white;
    color: white;
    width: 100%;
    position: absolute;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    z-index: 1;
    padding: 1rem;
  }
}

.accordionBody {
  padding: 1rem;
}

.accordionHeader {
  display: block;
  font-size: 0.875rem;
  padding: 0 0 0.25rem 0;
  color: #6c757d;
  white-space: nowrap;
}
.accordionText {
  display: block;
  padding: 0.25rem 0;
  color: #212529;
  font-size: 0.875rem;
}

.accordionDivider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
