@import "../../styles/colors";

.change-session-thumbnail {
  background-color: $scout_white;
  .btn {
    margin-top: 1rem;
  }

  &__header {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    background: $scout_grey;
    padding: 0.2rem 1rem 1rem 1rem;
    font-weight: bold;
  }
  &__images {
    &__current {
      margin-top: 2rem;
      width: 100%;
      &__header {
        text-align: center;
      }
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 400px;
        object-fit: cover;
      }
    }
    &__body {
      margin-top: 2rem;
      padding: 1rem;
      width: 100%;
      &__header {
        text-align: center;
      }
      &__inputgroup {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        justify-content: center;

        &__label {
          margin: 1em;
          img {
            max-height: 160px;
            max-width: 320px;
            cursor: pointer;
            object-fit: cover;
          }
        }
        &__radio {
          transform: scale(1.5);
          cursor: pointer;
        }
      }
    }
    &__button-group {
      display: flex;
      flex-flow: row;
      justify-content: center;
    }
  }

  &__none {
    &__body {
      margin-top: 1rem;
      padding: 1rem;
      width: 100%;
      justify-content: center;
    }
    &__button-group {
      display: flex;
      flex-flow: row;
      justify-content: center;
    }
  }
}

.modal-content {
  width: 100%;
}
