@import "../../styles/colors";

.run-prediction {
  display: flex;
  flex-flow: column;
  background-color: $scout_white;

  &__header {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    background: $scout_grey;
    padding: 0.2rem 1rem 1rem 1rem;

    &__title {
      font-weight: bold;
    }
  }
  &__prediction-model-info {
    border: 2px solid $scout_darkgrey;
    background-color: $scout_grey;
    margin: 10px auto 15px;
    text-align: center;
    padding: 15px;
    padding-top: 0;
    font-weight: bold;
    border-radius: 10px;

    &__header {
      margin-top: 1em;
      font-size: 16px;
      font-weight: bold;
    }
    .model-name {
      font-size: 18px;
      margin-bottom: 1em;
    }
    &__body {
      font-size: 12px;
    }
  }
  &__submitted {
    margin: 3em auto;
    &__header {
      text-align: center;
      margin-bottom: 1em;
    }
  }
}

.prediction-status {
  position: absolute;
  bottom: 100px;
  right: 100px;
  width: 200px;
  height: 100px;
  border: 2px solid $scout_darkgrey;
  background-color: $scout_grey;
  margin: 10px auto 15px;
  text-align: center;
  padding: 15px;
  padding-top: 0;
  font-weight: bold;
  border-radius: 10px;

  &__header {
    margin-top: 1em;
    font-size: 16px;
    font-weight: bold;
  }
  .model-name {
    font-size: 18px;
    margin-bottom: 1em;
  }
  &__body {
    font-size: 12px;
  }
}
