@import "../../styles/colors";
@import "../../styles/mixins";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $scout_black;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
}

.contentCollapse {
  position: absolute;
  background-color: rgba($scout_red, 0.5);
  transition: opacity 0.2s;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
}

.navbar {
  height: 2rem;
  display: flex;
  width: 100%;
  background-color: $scout_dark_black;
  padding: 0.5rem;
}

.nav {
  position: relative;
}

.scoutHeading {
  user-select: none;
  color: $scout_white;
  background-color: transparent;
  border: none;
  font-weight: 500;
  font-size: 0.8rem;
  font-family: "Roboto", sans-serif;
  margin-right: 1rem;
  padding-bottom: 1.2rem;
  border-bottom: 0.125rem solid transparent;
}

.scoutHeading:hover {
  border-bottom-color: $scout_red;
}

.scoutHeading:disabled {
  opacity: 0.5;
  border-bottom-color: transparent;
  pointer-events: none;
}

.activeTab {
  border-bottom-color: $scout_red;
  transition: opacity 0.3s;
}

.pane {
  position: absolute;
  width: 100%;
  height: 100%;
}

.activePane {
  visibility: visible;
}

.inactivePane {
  visibility: hidden;
  pointer-events: none;
}

.openButton {
  cursor: pointer;
  right: 0.9rem;
  position: absolute;
  color: $scout_white;
}
