.palletDropdown {
  display: flex;
  flex-direction: row;
  padding: 0.25rem 1.25rem;
  gap: 10px;
  align-items: center;
}

.palletDropdown:hover {
  background-color: #c4c4c4;
  cursor: pointer;
}
