@import "../../styles/colors";
@import "../../styles/mixins";

.navbar {
  background: $scout_dark_black;
  color: $scout_white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}
.button {
  color: white;
}
button.dropdown-toggle {
  color: white;
}

.navbar-nav {
  padding-top: 0.25rem;
  align-items: flex-end;

  > li {
    cursor: pointer;
    border-bottom: 0.125rem solid transparent;
    line-height: 1.375rem;
    width: fit-content;

    @include gt-lg {
      padding: 0.25rem;
      margin: 0.25rem;
    }

    &:hover,
    &.active-tab {
      border-bottom: 0.125rem solid $scout_red !important;
    }
  }

  .dropdown.nav-item {
    padding-top: 0.25rem;
  }
}

.navbar-nav.userAccountButtonMobile {
  @include gt-lg {
    display: none !important;
  }
}
.navbar-nav.userAccountButton {
  @include lt-lg {
    display: none;
  }
}

.navToggleContainer {
  @include lt-lg {
    flex-grow: 1;
    padding-right: 0.5rem;
  }
}
.navbar-dark button.navbar-toggler {
  @include lt-lg {
    float: right;
    color: white;
    width: 2rem;
    height: 2rem;
    padding: 0;
    border-color: transparent;
  }
}

.navbar-brand img {
  width: 9rem;
  margin-right: 0.4rem;
}

.scout-heading {
  font-weight: 500;
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
}

.nav-dropdown {
  background-color: unset !important;
  color: unset !important;
  display: inline !important;
}

.scout-navbar-external-link {
  color: white;
}

.scout-navbar-external-link:hover {
  all: unset;
}
