@import "../../styles/colors";
@import "../../styles/mixins";

.share-modal {
  &__body {
    display: flex;
    flex-flow: column;

    &__thumbnail {
      max-height: 160px;
      max-width: 320px;
      margin: 2rem;
    }
  }

  &__footer {
    padding: 1rem;
    border-top: 1px solid #dee2e6;
    display: flex;
    flex-flow: column;

    &__qr {
      margin: auto;
      padding: 1rem;
    }

    &__success {
      display: flex;

      input {
        flex: 1;
        font-style: italic;
        color: $scout_black;
      }
    }
  }
}
