.container {
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding: 0.5rem;
  gap: 0.5rem;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}
.dropdownContainer {
  width: 100%;
}

.dropdownMenu {
  width: 100%;
}

button.dropdownToggle {
  width: 100%;
  margin: 0;
  background: white;
  color: black;
  text-align: left;
  border: none;
  border-bottom: 2px solid lightgrey;
  border-radius: 0;
  box-shadow: lightgray;
  box-shadow: none;
}
button.dropdownToggle::after {
  color: hsl(0, 0%, 80%);
  border-left: 1px solid hsl(0, 0%, 80%);
  float: right;
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  text-decoration: none;
  padding-left: 1rem;
  margin-left: 1rem;
  font-size: 1.2rem;
}
button.dropdownToggle:hover:after {
  color: darkgrey;
}

button.dropdownToggle:hover,
button.dropdownToggle:active,
button.dropdownToggle:focus {
  color: black !important;
  background-color: white !important;
  border-color: darkgrey !important;
  box-shadow: none !important;
}

.selectionModeContainer {
  position: relative;
  width: 100%;
}

.cancelButton {
  position: absolute;
  right: 0px;
}
