@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/css?family=Titillium+Web");
@import "styles/colors";

html {
  height: 100%;
}

body {
  width: 100%;
  height: 100%;

  //Tucked Leather
  background:
    radial-gradient(transparent 37%, #282828 35%, #282828 70%, transparent 70%) 4px 4px,
    radial-gradient(black 30%, transparent 32%) 0 0,
    radial-gradient(rgba(255, 255, 255, 0.1) 30%, transparent 40%) 0 1px;
  background-color: #282828;
  background-size:
    16px 16px,
    8px 8px,
    8px 8px;
  margin: 0;
}

.root#root {
  height: 100%;
}

.titillium {
  font-family: "Titillium Web";
}

.titilliumHeading {
  font-family: "Titillium Web";
  font-size: 48px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: $scout_white;
}

.scoutQuote {
  font-family: "Roboto";
  font-weight: 300;
  color: #ef4036;
}

.scoutBread {
  font-family: "Roboto";
  color: #323232;
}

.tableLink {
  text-decoration: underline;
  color: blue;
}

td.tableLink:hover {
  cursor: pointer;
  color: lightblue;
}

td.tableLink:active {
  color: white;
}

.register-finding-label {
  font-family: "Roboto";
  font-weight: bold;
  color: #323232;
  font-size: 12px;
  margin-bottom: 1px;
}

.flex {
  display: flex;
}

.register-finding-form {
  margin-bottom: 10%;
}

button,
a,
.nav-item {
  transition: 0.2s all;
}

.modal-open {
  padding-right: 0 !important;
}

.form-field {
  display: flex;
  flex-flow: column;
  margin-bottom: 0.5rem;

  label {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0rem;
  }
}

.hr-vertical {
  height: 100%;
  width: 1px;
  border: none;
  border-left: 1px solid $divider_color;
}

.no-groups {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: -webkit-fill-available;
}

.btn {
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  &-transparent {
    background-color: transparent;
  }
}
.page-loading-spinner {
  width: 100%;
  margin: 1.5rem auto;
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.button-group {
  margin: 1rem 0;
}

.loading-spinner {
  color: $scout_blue;
}
