@import "../../styles/colors";

.new-credential {
  display: flex;
  flex-flow: column;
  background-color: $scout_white;

  &__header {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    background: $scout_grey;
    padding: 0.2rem 1rem 1rem 1rem;

    &__title {
      font-weight: bold;
    }
  }
}
.created-credential {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 1rem;

  hr {
    width: 100%;
  }

  span {
    width: fit-content;
    margin-bottom: 2rem;
  }

  .client-secret {
    font-size: 20px;
    font-weight: bold;
  }
}
